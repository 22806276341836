import React from "react";
import herobg from "../../assets/herobg.svg";
// import JotformEmbed from "react-jotform-embed";

export default function CookiePolicy() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${herobg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="h-10 flex items-center justify-center py-40 flex-col mb-20"
      >
       <div className="container mx-auto max-w-6xl text-center">
          <h2 className="text-white text-4xl my-4 tracking-wider leading-10d font-medium font-circularstd">
            Contact Us
          </h2>
        </div>
      </div>

      {/* <div className="container max-w-6xl mx-auto text-justify md:px-0 px-8">
        <div>
          <JotformEmbed src="https://form.jotform.com/93297042394968" />
        </div>
      </div> */}
    </>
  );
}