//English UK
export const en_GB = {
  hero: {
    customer: {
      heading: "Engage, Interact, Transact",
      subheading:
        "doodaa empowers customers to place an order, agree an amount and pay for goods and services from businesses near their current location. ",
    },
    business: {
      heading: "Connect, Fulfil, Grow",
      subheading:
        "doodaa business enables vendors and outlets to accept orders from customers in the local area, interact with them to agree on orders and prices and receive payments for fulfilling the orders.",
    },
  },
  aboutApp: {
    heading: "About the App",
    customer:
      "Engage with local businesses, outlets, shops, pubs, bars and restaurants. Interact using text, audio or video messages and agree on orders. Transact to complete contactless payment from mobile devices.",
    business:
      "Connect with customers from local surroundings. Fulfil orders by engaging with customers to agree on items and a price. Grow sales and receive payments promptly from customers.",
  },

  home_terms: {
    heading: "doodaa Terms and Conditions of Use",
    one: "By downloading or otherwise accessing the doodaa App or Platform you agree to be bound by the following terms and conditions (“Terms”), our",
    two: "and our",
    three: "If you have any queries about the App or the terms, you can",
    four: "If you do not agree with the terms, you should stop using the App and the Platform immediately.",
    five: "Read More",
  },

  download: {
    download: "download",
    scan: "scan or click on image",
  },
};

//English US
export const en_US = {
  hero: "english us",
};

//Hindi India
export const hi_IN = {
  hero: {
    customer: {
      heading: "व्यस्त रहें, बातचीत करें, लेन-देन करें",
      subheading:
        "doodaa ग्राहकों को एक ऑर्डर देने, एक राशि पर सहमत होने और अपने वर्तमान स्थान के पास के व्यवसायों से वस्तुओं और सेवाओं के लिए भुगतान करने का अधिकार देता है।",
    },
    business: {
      heading: "कनेक्ट करें, पूरा करें, आगे बढ़ें",
      subheading:
        "doodaa व्यवसाय विक्रेताओं और आउटलेट्स को स्थानीय क्षेत्र में ग्राहकों से ऑर्डर स्वीकार करने, ऑर्डर और कीमतों पर सहमत होने के लिए उनके साथ बातचीत करने और ऑर्डर को पूरा करने के लिए भुगतान प्राप्त करने में सक्षम बनाता है।",
    },
  },
  aboutApp: {
    heading: "ऐप के बारे में",
    customer:
      "स्थानीय व्यवसायों, दुकानों, दुकानों, पब, बार और रेस्तरां से जुड़ें। पाठ, ऑडियो या वीडियो संदेशों का उपयोग करके बातचीत करें और आदेशों पर सहमत हों। मोबाइल उपकरणों से संपर्क रहित भुगतान पूरा करने के लिए लेनदेन करें।",
    business:
      "स्थानीय परिवेश के ग्राहकों से जुड़ें। वस्तुओं और कीमत पर सहमत होने के लिए ग्राहकों के साथ जुड़कर ऑर्डर पूरा करें। बिक्री बढ़ाएं और ग्राहकों से तुरंत भुगतान प्राप्त करें।",
  },

  home_terms: {
    heading: "doodaa उपयोग के नियम और शर्तें",
    one: "doodaa ऐप या प्लेटफॉर्म को डाउनलोड या अन्यथा एक्सेस करके आप निम्नलिखित नियमों और शर्तों (नियम) से बाध्य होने के लिए सहमत हैं, हमारे",
    two: "और हमारा",
    three:
      "यदि आपके पास ऐप या शर्तों के बारे में कोई प्रश्न हैं, तो आप कर सकते हैं",
    four: "यदि आप शर्तों से सहमत नहीं हैं, तो आपको ऐप और प्लेटफ़ॉर्म का उपयोग तुरंत बंद कर देना चाहिए।",
    five: "अधिक पढ़ें",
  },

  download: {
    download: "download",
    scan: "scan or click on image",
  },
};
