import React from "react";
import herobg from "../../assets/herobg.svg";

export default function CookiePolicy() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${herobg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="h-20 flex items-center justify-center py-40 flex-col mb-20"
      >
        <div className="container mx-auto max-w-6xl text-center">
          <h2 className="text-white text-4xl my-4 tracking-wider leading-10d font-medium font-circularstd">
            Cookie Policy
          </h2>
        </div>
      </div>

      <div className="container max-w-6xl mx-auto text-justify md:px-0 px-8">
        <div>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            doodaa™ is a registered trademark of Apex Enterprise Solutions
            Limited, Copyright 2021-2022. All rights reserved.
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            doodaa (“doodaa “, “we”, “our”, “us) is a trading name of Apex
            Enterprise Solutions Limited. We respect the privacy rights of its
            online visitors (“you”, “your”, “yours”) and recognise the
            importance of protecting the information collected about them. We
            have adopted a corporate wide Privacy Policy that guides how we
            collect, store, and use the information that you provide us with.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            What is a Cookie
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            A cookie is a simple text file that is stored on your computer or
            mobile device by a website’s server and only that server will be
            able to retrieve or read the contents of that cookie. Each cookie is
            unique to your web browser. It will contain some anonymous
            information such as a unique identifier and the site name and some
            digits and numbers. It allows a website to remember things like your
            preferences or what’s in your shopping basket. Most websites you
            visit will use cookies in order to improve your user experience by
            enabling that website to ‘remember’ you, either for the duration of
            your visit (using a ‘session cookie’) or for repeat visits (using a
            ‘persistent cookie’).
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Cookies do lots of different jobs, like letting you navigate between
            pages efficiently, storing your preferences, and generally improving
            your experience of a website. Cookies make the interaction between
            you and the website faster and easier. If a website doesn’t use
            cookies, it will think you are a new visitor every time you move to
            a new page on the site – for example, when you enter your login
            details and move to another page it won’t recognise you and it won’t
            be able to keep you logged in.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Some websites will also use cookies to enable them to target their
            advertising or marketing messages based for example, on your
            location and/or browsing habits.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Cookies may be set by the website you are visiting (‘first party
            cookies’) or they may be set by other websites who run content on
            the page you are viewing (‘third party cookies’). See below for more
            information.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            What to do if you don't want cookies to be set
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Some people find the idea of a website storing information on their
            computer or mobile device a bit intrusive, particularly when this
            information is stored and used by a third party without them
            knowing. Although this is generally quite harmless you may not, for
            example, want to see advertising that has been targeted to your
            interests. If you prefer, it is possible to block some or all
            cookies, or even to delete cookies that have already been set; but
            you need to be aware that you might lose some functions of that
            website.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            What cookies we use
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Our site uses cookies (very small files that are sent by us to your
            computer or other access device) which we can access when you visit
            our site in future. There are four types of cookies:
          </p>
          <p>
            1. Website functionality cookies: These cookies enable you to browse
            our website and use features such as shopping baskets and wish
            lists.
          </p>
          <p>
            2. Website analytics cookies: We use these cookies to measure and
            analyse how our customers use the website. This allows us to
            continuously improve our website and your shopping experience.
          </p>
          <p>
            3. Customer preference cookies: When browsing or shopping online,
            the website will remember preferences you make (for example your
            user name, language or location). This makes your browsing
            experience simpler, easier and more personal to you.
          </p>
          <p>
            4. Targeting cookies or advertising cookies: These cookies are used
            to deliver adverts relevant to you. In addition, they limit the
            number of times you see an advertisement as well as helping us
            measure the effectiveness of our advertising campaigns.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Consent
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            By using our website you agree that we can place these types of
            cookies on your device and access them when you visit the site in
            the future. If you want to delete any cookies that are already on
            your computer, please refer to the help and support area on your
            internet browser for instructions on how to locate the file or
            directory that stores cookies.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Information on deleting or controlling cookies is available at
            www.AboutCookies.org. Please note that by deleting our cookies or
            disabling future cookies you may not be able to access certain areas
            or features of our site.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Please note that by deleting our cookies or disabling future cookies
            you may not be able to access certain areas or features of our site
            and it may become unusable.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Where and how to ask questions and how to file complaints
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            If you require further information about our Privacy Policy or
            Cookie Policy, please email&nbsp;
            <a
              href="mailto:info@doodaa.co"
              className="text-theme underline leading-7 tracking-wider"
            >
              info@doodaa.co
            </a>
            .
          </p>
          doodaa™ is a registered trademark of Apex Enterprise Solutions
          Limited, Copyright 2021-2022. All rights reserved.
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal "></p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Last updated: 19 December 2021
          </p>
        </div>
      </div>
    </>
  );
}
