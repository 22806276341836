import React from "react";
import downloadc from "../assets/downloadc.png";
import downloadb from "../assets/downloadb.png";
import dcust from "../assets/dcust.png";
import dbus from "../assets/dbus.png";
import { useLocation } from "react-router";
import { navigation_links } from "./Constants";
import { useTranslation } from "react-i18next";

export default function Download() {
  const loc = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <section className="text-gray-600 body-font overflow-hidden my-36">
        <div className="container px-5  mx-auto bg-download_bg rounded-3xl">
          <div className="lg:w-4/5 mx-auto flex flex-wrap items-center justify-between  p-8 ">
            <div className="h-64 w-full relative bg-transparent lg:mt-0 mt-20">
              <div className="w-6/12 absolute right-0">
                <h1 className="text-text_color lg:text-4xl text-base font-medium mb-4 lg:leading-10 leading-normal">
                  &nbsp;{t("download.download")}
                </h1>
                <a
                    href={
                      loc.pathname === "/"
                          ? navigation_links.doodaa_customer
                          : navigation_links.doodaa_business
                    }
                >
                    <img
                      className="rounded w-44"
                      alt="QR code"
                      src={loc.pathname === "/" ? dcust : dbus}
                    />
                </a>
                <p className="mb-4 font-normal text-lg text-text_color">
                    {t("download.scan")}
                </p>
              </div>
            </div>
            <div className="absolute flex justify-start items-center">
              <div>
                <img
                  alt="ecommerce"
                  className="h-44 lg:h-auto"
                  src={loc.pathname === "/" ? downloadc : downloadb}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
