export const navigation_links = {
  google_play_customer:
    "https://play.google.com/store/apps/details?id=co.doodaa",
  google_play_business:
    "https://play.google.com/store/apps/details?id=co.doodaa.business",
  ios_play_customer: "https://apps.apple.com/us/app/doodaa/id1578992259",
  ios_play_business:
    "https://apps.apple.com/us/app/doodaa-business/id1577922251",
  video_customer:
    "https://doodaa.s3.eu-west-2.amazonaws.com/common/Doodaa_Customer1_1.mp4",
  video_business:
        "https://doodaa.s3.eu-west-2.amazonaws.com/common/Doodaa_Business+(1).mp4",
  doodaa_customer:
        "https://doodaa.page.link/6SuK",
  doodaa_business:
        "https://doodaa.page.link/y1E4",
};
