import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router";
import Toggle from "./Toggle";
export default function Header() {
  const [offset, setoffset] = useState(0);
  const [isSidebar, setisSidebar] = useState(false);
  const loc = useLocation();

  useEffect(() => {
    window.onscroll = () => {
      setoffset(window.pageYOffset);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {loc.pathname.includes("business") ? "doodaa business" : "doodaa"}
        </title>
      </Helmet>
      <header
        className={`fixed z-40 w-screen  ${offset > 20 ? "bg-theme shadow-sm" : "bg-transparent"
          }
          `}
      >
        <nav>
          <div className="container mx-auto flex flex-wrap p-4  max-w-full items-center">
            <div className="flex-1">
              <a
                href={loc.pathname.includes("/business") ? "/business" : "/"}
                className="flex title-font font-medium items-center"
              >
                <span className="ml-3 text-4xl text-white">
                  {" "}
                  {loc.pathname.includes("/business")
                    ? "doodaa business"
                    : "doodaa"}
                </span>
              </a>
            </div>
            <div className="hidden items-center md:flex">
              <a
                href={loc.pathname.includes("/business") ? "/business" : "/"}
                className="mr-5 text-white"
              >
                Home
              </a>
              <a
                href={
                  loc.pathname.includes("/business") ? "/business/tnc" : "/tnc"
                }
                className="mr-5 text-white"
              >
                Terms & Conditions
              </a>

              <a
                href={
                  loc.pathname.includes("/business")
                    ? "/business/privacy-policy"
                    : "/privacy-policy"
                }
                className="mr-5 text-white"
              >
                Privacy Policy
              </a>

              <a
                href={
                  loc.pathname.includes("/business")
                    ? "/business/cookie-policy"
                    : "/cookie-policy"
                }
                className="mr-5 text-white"
              >
                Cookie Policy
              </a>
              <a
                href={loc.pathname.includes("/business") ? "/" : "/business"}
                className="mr-5 text-white flex hover:underline"
              >
                {loc.pathname.includes("/business")
                  ? "Switch to Customer"
                  : "Switch to Business"}
              </a>
            </div>

            <div
              className="flex md:hidden btn cursor-pointer"
              onClick={() => setisSidebar(!isSidebar)}
            >
              {!isSidebar ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </div>
          </div>
        </nav>

        {isSidebar && (
          <aside className="drawer  p-6 absolute bg-theme top-20 w-full">
            <div className="flex flex-col space-y-4 border-t-1 items-center">
              <a
                href={loc.pathname === "/" ? "/" : "/business"}
                className="text-white"
                onClick={() => setisSidebar(!isSidebar)}
              >
                Home
              </a>
              <a
                href={loc.pathname === "/" ? "/tnc" : "/business/tnc"}
                className="text-white"
                onClick={() => setisSidebar(!isSidebar)}
              >
                Terms & Conditions
              </a>
              <a
                href={
                  loc.pathname === "/"
                    ? "/privacy-policy"
                    : "/business/privacy-policy"
                }
                className="text-white"
                onClick={() => setisSidebar(!isSidebar)}
              >
                Privacy
              </a>
              <a
                href={
                  loc.pathname === "/"
                    ? "/cookie-policy"
                    : "/business/cookie-policy"
                }
                className="text-white"
                onClick={() => setisSidebar(!isSidebar)}
              >
                Cookie Policy
              </a>

              <p className="text-white">
                {loc.pathname.includes("/business")
                  ? "Switch to Customer"
                  : "Switch to Business"}
              </p>
              <a
                href={loc.pathname.includes("/business") ? "/" : "/business"}
                className="mr-5 text-white flex"
              >
                <Toggle name="toggle" />
              </a>
            </div>
          </aside>
        )}
      </header>

      <button
        onClick={scrollToTop}
        title="Scroll to Top"
        className={`items-center justify-center fixed bottom-10 right-10 bg-black shadow-2xl rounded-full text-white p-4 z-10 ${offset > 700 ? "block" : "hidden"
          }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-arrow-up"
        >
          <line x1="12" y1="19" x2="12" y2="5"></line>
          <polyline points="5 12 12 5 19 12"></polyline>
        </svg>
      </button>
    </>
  );
}
