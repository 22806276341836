import React from "react";
import herobg from "../../assets/herobg.svg";

export default function PrivacyPolicy() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${herobg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="h-10 flex items-center justify-center py-40 flex-col mb-20"
      >
        <div className="container mx-auto max-w-6xl text-center">
          <h2 className="text-white text-4xl my-4 tracking-wider leading-10d font-medium font-circularstd">
            Privacy Policy
          </h2>
        </div>
      </div>

      <div className="container max-w-6xl mx-auto text-justify md:px-0 px-8">
        <div>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            doodaa™ is a registered trademark of Apex Enterprise Solutions
            Limited, Copyright 2021-2022. All rights reserved.
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            At Apex Enterprise Solutions Limited (“doodaa”) your privacy is of
            utmost importance to us and we are committed to protecting and
            respecting it.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            This policy together with our terms and conditions and any other
            documents referred to on it sets out the basis on which any personal
            data we collect from you, or that you provide to us, will be
            processed by us. Please read the following carefully to understand
            our views and practices regarding your personal data and how we will
            treat it.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            The operator and, for the purposes of the Data Protection Act 1998
            (the “Act”), the data controller is Apex Enterprise Solutions
            Limited (Company No. 08735516) of 71-75 Shelton Street, Covent
            Garden, London, WC2H 9JQ.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            The information we collect and how we use it
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            We collect and process the following data about you:
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – Information that you provide by signing up to mobile application
            doodaa (the “App”).
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – This includes information provided at the time of registering to
            use our mobile application, subscribing to our service, posting
            material or requesting further services (the “Service”).
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – We may request further information when you report a problem with
            the doodaa App, doodaa.co Website or the doodaa Platform or
            subscribe to our newsletter.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – If you contact us, we may keep a record of that interaction or any
            correspondence relating to that interaction.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – We may also ask you to complete surveys that we use for research
            purposes, although you do not have to respond to them.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – Details of transactions you effect through the doodaa App, Website
            or Platform and of the fulfilment of your Orders.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – Details of your use of the App, but not limited to, traffic data,
            location data, weblogs and other communication data, whether this is
            required for our own billing purposes or otherwise and the resources
            that you access.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Computer and device information
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            We may collect information about your smart phone, including where
            available your IP address, operating system and browser type, for
            system administration and to aggregate information to our partners.
            This is anonymous technical data about usage actions and patterns,
            and will not divulge any data capable of identifying you personally.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Location information
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            To use our services to identify nearby businesses and merchants, the
            App collects real-time information about the location of you device,
            as permitted by you.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Payment card information
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            To use our services to make payments to businesses and merchants, we
            require credit or debit card account information. By submitting your
            credit or debit card details, you expressly consent to the sharing
            of your information with third-party payment processors and other
            third-party service providers (including but not limited to vendors
            who provide fraud detection services to us and other third parties)
            and you further agree to the following terms:
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – When you use a credit or debit card to make a payment, we provide
            your credit or debit card details (including card number and expiry
            date) to our third-party payment service providers.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – When you provide your credit or debit card information on our App,
            our third-party payment service providers may store your credit or
            debit card information so you can use our payment services in the
            future.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            The third-party service providers with respect to payment processing
            are validated as compliant with the payment card industry standard,
            also referred to as PCI compliant service providers.
          </p>

          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Cookies
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Our website uses cookies to distinguish you from other users of our
            website. This helps us to provide you with a good experience when
            you browse our website and also allows us to improve our site. For
            detailed information on the cookies we use and the purposes for
            which we use them see our Cookie policy.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Stored information
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            The data that we collect from you may be transferred to, and stored
            at, a destination outside the United Kingdom and the European
            Economic Area (“EEA”). It may also be processed by staff operating
            outside the UK or the EEA who work for us or for one of our
            suppliers. Such staff maybe engaged in, among other things, the
            processing of your payment details and the provision of support
            services.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            By submitting your personal data, you agree to this transfer,
            storing or processing. We will take all steps reasonably necessary
            to ensure that your data is treated securely and in accordance with
            this privacy policy.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            We do not store credit card details nor do we share financial
            details with any 3rd parties. Where you have chosen a password or
            personal identification number (“PIN”) which enables you to access
            certain parts of the application, you are responsible for keeping
            this password or PIN confidential. We ask you not to share a
            password or PIN with anyone.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Unfortunately, no data transmission over the Internet or data
            storage system can be guaranteed to be 100% secure. As a result,
            although we strive to protect your personal data, we cannot ensure
            or warrant the security of any information you transmit to us
            through or in connection with the App or that is stored by us. Once
            we have received your information, we will use strict procedures and
            security features to try to prevent unauthorised access.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Please note that email is not encrypted, and is NOT a secure means
            of transmitting credit or debit card information. We will NEVER
            request sensitive information over email.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            How we use the information we collect
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            We use information held about you in the following ways:
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to provide the App services to you including processing any
            payments;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to notify you about changes to the App or respond to any
            communication or request from you;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – unless you have told us you do not want to receive marketing
            communications, to provide you with marketing communications about
            our products and services and those of third parties which we think
            may be of interest to you;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to monitor the use of the App, the Website or the Platform;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to investigate any complaints or potential breaches of the
            service; and for debt tracing, debt recovery, credit management and
            crime, fraud and money laundering detection and prevention.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to provide your information to the relevant Merchant(s) for whose
            products or goods you have placed orders so they can supply the
            products or goods to you and for customer service and administration
            purposes.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to ensure that contents from our App or Website are presented in
            the most effective manner for you and for your computer, mobile,
            tablet or internet device.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to provide you with information, products or services that you
            request from us or which we feel may interest you, where you have
            consented to be contacted for such purposes.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to carry out our obligations arising from any contracts entered
            into between you and our merchants (or us).
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to allow you to participate in interactive features of our
            service, when you choose to do so.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to plan and manage our App, Website or Platform and our
            businesses, including analysis of customers’ shopping habits,
            location, price, product and style preferences.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to notify you about changes to our service.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            We may also use your data, or permit selected third parties to use
            your data, to provide you with information about goods and services
            which may be of interest to you and we or they may contact you about
            these by post or telephone.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            If you are an existing customer, we will only contact you by
            electronic means (e-mail or SMS) with information about goods and
            services similar to those which were the subject of a previous sale
            to you.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            If you are a new customer, and where we permit selected third
            parties to use your data, we (or they) will contact you by
            electronic means only if you have consented to this.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            If you do not want us to use your data in this way, or to pass your
            details on to third parties for marketing purposes, please tick the
            relevant box situated on the form or webpage on which we collect
            your data.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            How we share the information we collect
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            We only disclose Your Information as follows:
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to any suppliers and subcontractors (including payment providers)
            in order to fulfil the service; In the event that we sell or buy any
            business or assets, in which case we may disclose your personal data
            to the prospective seller or buyer of such business or assets; If
            Apex Enterprise Solutions (doodaa) or substantially all of its
            assets are acquired by a third party, in which case personal data
            held by it about its customers will be one of the transferred
            assets;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to credit reference agencies and/or fraud detection and prevention
            agencies;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to any relevant public authority or law enforcement agency,
            including for example where we suspect you have committed a criminal
            offence or we suspect fraud where required by law, regulation or
            legal proceedings; unless you have told us that you do not want to
            receive marketing communications, to third parties engaged by us to
            provide you with marketing communications about our products and
            services. This may also include social media platforms (including
            Facebook) where we provide limited information about our users (e.g.
            their email addresses) to the social media platform so they can
            provide to us an anonymous demographic profile of our users with
            profiles on that platform to inform our targeted marketing
            activities on that platform;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            – to third parties from whom you have chosen to receive marketing
            information; and you should take care when using any doodaa (Apex
            Enterprise Solutions) social networking features since the
            information you choose to make available can be seen by other users
            in the selected group. We do not monitor the use of such features in
            the normal course of business although we reserve the right to do so
            at our discretion.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            How we protect your information
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            All information you provide to us is stored on our secure servers.
            Where you have given a password (in the understanding it would be
            secure) which enables you to access certain parts of the Service,
            you are responsible for keeping this password confidential. We ask
            you not to share a password with anyone.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Disclosure of your information
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            We may disclose your personal information to any member of our
            group, which means our subsidiaries, our ultimate holding company
            and its subsidiaries, as per the UK Companies Act 2006. We may
            disclose your personal information to third parties as described in
            this policy.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Your rights
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            You have the right to ask us not to process your personal data for
            marketing purposes. We will usually inform you (before collecting
            your data) if we intend to use your data for such purposes or if we
            intend to disclose your information to any third party for such
            purposes. You can exercise your right to prevent such processing by
            checking certain boxes on the forms we use to collect your data. You
            can also exercise the right at any time by contacting us at
            privacy@doodaa.co.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            You may unsubscribe from our newsletters or promotional emails by
            clicking on the ‘unsubscribe’ link at the bottom of such emails or
            by writing to us at privacy@doodaa.co.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            You may request us to delete your account by emailing us at
            privacy@doodaa.co. Your account will be deactivated and you will no
            longer hear from us, however all transaction related data may be
            held for up to 6 years to meet regulatory obligations for such data.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Access to information
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            The Act gives you the right to access information held about you.
            Your right of access can be exercised in accordance with the Act. We
            may make a small charge for this. You may ask us to correct or
            remove information you think is inaccurate by contacting us. We hope
            you will be happy with the way we handle your information but if not
            you have the right to complain to the Information Commissioner if
            there is a problem – for more information visit www.ico.gov.uk.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Changes to our Privacy Policy
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            Any changes we may make to our privacy policy in the future will be
            posted on this page. It is your responsibility to review this page
            and policy for any changes we make from time to time.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Contact
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            If you have any questions or comments regarding this policy, you can
            contact us at&nbsp;
            <a
              href="mailto:support@doodaa.co"
              className="text-theme underline leading-7 tracking-wider"
            >
              privacy@doodaa.co
            </a>
            .
            <br />
            doodaa™ is a registered trademark of Apex Enterprise Solutions
            Limited, Copyright 2021-2022. All rights reserved.
            <br />
            Last updated: 19 December 2021
          </p>
        </div>
      </div>
    </>
  );
}
