import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

export default function AboutApp() {
  const loc = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <section className="body-font my-20 text-center">
        <div className="container p-12 mx-auto  shadow-lg rounded-3xl max-w-5xl">
          <div className="flex flex-col text-center items-center w-full mb-8">
            <h1 className="sm:text-3xl text-4xl font-medium title-font text-text_color">
              {t("aboutApp.heading")}
            </h1>
            <div className="h-1 w-24 my-4 bg-theme"></div>
          </div>
          <div className="flex flex-wrap justify-center -m-4 px-4 text-center">
            {loc.pathname === "/" ? (
              <p className="mb-4 font-normal text-lg text-text_color text-center">
                {t("aboutApp.customer")}
              </p>
            ) : (
              <p className="mb-4 font-normal text-lg text-text_color text-center">
                {t("aboutApp.business")}
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
