import React, { useState } from "react";
import videobg1 from "../assets/videobg1.png";
import videobg2 from "../assets/videobg2.png";
import playbtn from "../assets/playbtn.png";
import { useLocation } from "react-router-dom";
import { navigation_links } from "./Constants";

export default function Video() {
  const [isVideo, setisVideo] = useState(false);
  const loc = useLocation();

  const handleToggle = () => {
    setisVideo(!isVideo);
  };
  return isVideo ? (
    <div className="w-full flex justify-center">
      <video width={"70%"} controls autoPlay>
        <source
          src={
            loc.pathname.includes("/business")
              ? navigation_links.video_business
              : navigation_links.video_customer
          }
          type="video/mp4"
        />
      </video>
    </div>
  ) : (
    <div
      className="w-full flex justify-center items-center h-screen"
      style={{
        backgroundImage: `url(${
          loc.pathname.includes("/business") ? videobg2 : videobg1
        })`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="absolute">
        <img
          src={playbtn}
          alt="Play"
          className="cursor-pointer"
          onClick={handleToggle}
        />
      </div>
    </div>
  );
}
