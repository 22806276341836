import React from "react";
import { useLocation } from "react-router";
import i18n from "i18next";

export default function Footer() {
  const loc = useLocation();

  const onChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <>
      <footer className="body-font bg-theme">
        <div className="container px-5 pt-24 pb-12 mx-auto flex justify-start flex-col md:flex-row">
          <div className="p-4 lg:w-1/2 w-full text-center flex-1">
            <h1 className="text-white text-4xl font-medium">doodaa... done!</h1>
            <div className="flex rounded-lg p-8 sm:flex-row flex-col items-center justify-around">
              <nav className="list-none lg:my-0 my-10">
                <li>
                  <a
                    href={
                      loc.pathname.includes("/business")
                        ? "/business/tnc"
                        : "/tnc"
                    }
                    className="text-white leading-7 tracking-wider"
                  >
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a
                    href={
                      loc.pathname.includes("/business")
                        ? "/business/contact-us"
                        : "mailto:support@doodaa.co"
                    }
                    className="text-white leading-7 tracking-wider"
                  >
                    Contact Us
                  </a>
                </li>
              </nav>
              <nav className="list-none lg:mb-0 mb-10">
                <li>
                  <a
                    href={
                      loc.pathname.includes("/business")
                        ? "/business/privacy-policy"
                        : "/privacy-policy"
                    }
                    className="text-white leading-7 tracking-wider"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href={
                      loc.pathname.includes("/business")
                        ? "/business/cookie-policy"
                        : "/cookie-policy"
                    }
                    className="text-white leading-7 tracking-wider"
                  >
                    Cookie Policy
                  </a>
                </li>
              </nav>
            </div>
          </div>

          {/* <div className="p-4 lg:w-1/2 w-full">
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-white text-4xl font-medium">Subscribe</h1>
              <p className="text-white">Sign up for updates</p>
            </div>
            <div className="flex rounded-lg sm:flex-row flex-col items-center justify-center">
              <div className="flex flex-col items-start justify-start">
                <input
                  type="text"
                  placeholder="Email"
                  className="w-full px-4 py-2 mt-2 text-white bg-transparent focus:outline-none placeholder-white border-white border-b-2 "
                />
                <button className="bg-white text-black px-4 py-2 rounded-lg my-3">
                  Subscribe
                </button>
              </div>
            </div>
          </div> */}
        </div>

        <div className="bg-theme">
          <div className="container max-w-4xl px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
            <a
              href="/"
              className="flex  title-font font-medium items-center md:justify-start justify-center text-white"
            >
              <p className="text-sm text-white sm:ml-6 sm:mt-0 mt-4">
                © {new Date().getFullYear()} doodaa
              </p>
            </a>
            <div className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <select
                onChange={onChange}
                className="form-select appearance-none px-3 py-1.5 text-xs font-normal  bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Language"
              >
                <option defaultValue value="en">
                  English GB
                </option>
                <option value="en_US">English US</option>
                <option value="hi">हिन्दी</option>
              </select>
            </div>
            {/* <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <a href="/ " className="text-white">
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
              <a href="/ " className="ml-3 text-white">
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                </svg>
              </a>
              <a href="/ " className="ml-3 text-white">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
              <a href="/ " className="ml-3 text-white">
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="none"
                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                  ></path>
                  <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
              </a>
            </span> */}
          </div>
        </div>
      </footer>
    </>
  );
}
