import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

export default function HomeTerms() {
  const loc = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <section className="body-font my-20 text-center">
        <div className="container p-12 mx-auto max-w-5xl ">
          <div className="flex flex-col text-center items-center w-full mb-4">
            <h1 className="sm:text-3xl text-4xl font-medium  text-text_color">
              {t("home_terms.heading")}
            </h1>
            <div className="h-1 w-24 my-4 bg-theme"></div>
          </div>

          <div className="flex flex-wrap items-center justify-center px-4">
            <p className="mb-4 font-normal text-lg text-text_color">
              {t("home_terms.one")}{" "}
              <a
                className="text-theme underline"
                href={
                  loc.pathname === "/"
                    ? "/privacy-policy"
                    : "/business/privacy-policy"
                }
              >
                privacy policy
              </a>{" "}
              {t("home_terms.two")}{" "}
              <a
                className="text-theme underline"
                href={
                  loc.pathname === "/"
                    ? "/cookie-policy"
                    : "/business/cookie-policy"
                }
              >
                cookie policy
              </a>
              . {t("home_terms.three")}&nbsp;
              <a
                href="mailto:support@doodaa.co"
                className="text-theme underline leading-7 tracking-wider"
              >
                email us
              </a>
              . {t("home_terms.four")}
            </p>

            <a href={loc.pathname === "/" ? "/tnc" : "/business/tnc"}>
              <button className="bg-theme h-14 px-10 py-2 rounded-2xl text-white">
                {t("home_terms.five")}
              </button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
