import React, { Suspense, useEffect } from "react";
import ReactGA from "react-ga";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./components/Routing";
import { HelmetProvider } from "react-helmet-async";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en_GB, en_US, hi_IN } from "./components/translations";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en_GB },
    hi: { translation: hi_IN },
    en_US: { translation: en_US },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

const App = () => {

  useEffect(() => {
    ReactGA.initialize("AW-11454320037");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  return (
    <>
      <Suspense fallback="Loading...">
        <HelmetProvider>
          <Router>
            <Routing />
          </Router>
        </HelmetProvider>
      </Suspense>
    </>
  );
};

export default App;
