import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import AboutApp from "./AboutApp";
import Download from "./Download";
import Footer from "./Footer";
import Header from "./Header";
import Hero from "./Hero";
import HomeTerms from "./HomeTerms";
import BCookiePolicy from "./Internal Pages/BCookiePolicy";
import BPrivacyPolicy from "./Internal Pages/BPrivacyPolicy";
import BContactUs from "./Internal Pages/BContactUs";
import BTnc from "./Internal Pages/BTnC";
import CookiePolicy from "./Internal Pages/CookiePolicy";
import PrivacyPolicy from "./Internal Pages/PrivacyPolicy";
import Tnc from "./Internal Pages/TnC";
import Video from "./Video";
import { useLocation } from "react-router";
const favIconElement = () => {
  return document.getElementById("favicon");
};
export default function Routing() {
  const loc = useLocation();
  useEffect(() => {
    if (loc.pathname.includes("/business")) {
      const favicon = favIconElement();
      favicon.href = "./doodaaB.png";
    } else {
      const favicon = favIconElement();
      favicon.href = "./doodaaC.png";
    }
  }, [loc.pathname]);

  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/">
          <Hero />
          <AboutApp />
          <Video />
          <HomeTerms />
          <Download />
        </Route>

        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/cookie-policy">
          <CookiePolicy />
        </Route>
        <Route exact path="/tnc">
          <Tnc />
        </Route>
        <Route exact path="/business">
          <Hero />
          <AboutApp />
          <Video />
          <HomeTerms />
          <Download />
        </Route>
        <Route exact path="/business/tnc">
          <BTnc />
        </Route>
        <Route exact path="/business/privacy-policy">
          <BPrivacyPolicy />
        </Route>
        <Route exact path="/business/cookie-policy">
          <BCookiePolicy />
        </Route>
        <Route exact path="/business/contact-us">
          <BContactUs />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}
