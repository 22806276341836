import React, { useState } from "react";
import herobg from "../assets/herobg.svg";
// import heroimg from "../assets/heroimg.svg";
// import hero from "../assets/hero.svg";
import heroC from "../assets/heroC.png";
import heroB from "../assets/heroB.png";
import gplay from "../assets/gplay.svg";
import appplay from "../assets/appplay.svg";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { navigation_links } from "./Constants";

export default function Hero() {
  const loc = useLocation();
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const handleDrawer = () => {
    setisDrawerOpen(!isDrawerOpen);
  };
  return (
    <>
      <section
        style={{
          backgroundImage: `url(${herobg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col-reverse items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            {/* <p className="mb-4 leading-relaxed text-white text-2xl">
              It is a long established fact
            </p> */}
            <h1 className="title-font text-6xl mb-4 font-medium text-white">
              {loc.pathname === "/"
                ? t("hero.customer.heading")
                : t("hero.business.heading")}
            </h1>
            <p className="mb-4 leading-relaxed text-white text-base">
              {loc.pathname === "/"
                ? t("hero.customer.subheading")
                : t("hero.business.subheading")}
            </p>

            <div className="flex justify-center">
              <button>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    loc.pathname === "/"
                      ? navigation_links.google_play_customer
                      : navigation_links.google_play_business
                  }
                >
                  <img className="rounded w-28" alt="hero" src={gplay} />
                </a>
              </button>

              <button className="ml-4">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    loc.pathname === "/"
                      ? navigation_links.ios_play_customer
                      : navigation_links.ios_play_business
                  }
                >
                  <img className="rounded w-28" alt="hero" src={appplay} />
                </a>
              </button>
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-center w-full"
              alt="hero"
              src={loc.pathname === "/" ? heroC : heroB}
            />
          </div>
        </div>
      </section>
      {loc.pathname !== "/" && (
        <button
          onClick={handleDrawer}
          title="Open Contact Form"
          className="items-center animate-bounce justify-center fixed bottom-10 left-10 bg-green-700 shadow-xl rounded-full text-white p-2 z-10 block"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-phone"
          >
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
          </svg>
        </button>
      )}

      {loc.pathname !== "/" && isDrawerOpen ? (
        <div
          class="fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform -translate-x-full bg-white w-100 dark:bg-gray-800"
          tabindex="-1"
        >
          <button
            type="button"
            onClick={handleDrawer}
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 left-2.5 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </button>
          <div className="p-4 mt-3 w-full h-full overflow-auto">
            <iframe src="https://form.jotform.com/240543981393058" style={{border:"none"}} height="90%" width="100%" title="description"></iframe>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
