import React from "react";
import herobg from "../../assets/herobg.svg";

export default function Tnc() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${herobg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="h-10 flex items-center justify-center py-40 flex-col mb-20"
      >
        <div className="container mx-auto max-w-6xl text-center">
          <h2 className="text-white text-4xl my-4 tracking-wider leading-10d font-medium font-circularstd">
            Terms and Conditions of Use
          </h2>
        </div>
      </div>

      <div className="container max-w-6xl mx-auto text-justify md:px-0 px-8">
        <div>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Definitions
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            “User” or “App User” or “doodaa App User” means You – an individual
            who has signed up to use the doodaa App or Platform as per the Terms
            and Conditions detailed below. “Contract” or “Transaction” or
            “Order” means the Contract for the sale and purchase of any Products
            or Goods between the user and the Merchant. “The App” or “the doodaa
            App” means the doodaa Smartphone Application. “Website” or
            “Webportal” means www.doodaa.co. “The doodaa Platform” or “The
            Platform” collectively represents the doodaa App and doodaa
            Website/Webportal. “Merchant” or “Vendor” means a business that
            offers its Products or Goods for sale through the Platform.
            “Products” or “Goods” or “Item” any items advertised for sale by a
            Merchant through the doodaa Platform. “Order” an offer to purchase
            Products or Goods from a Merchant/Vedor through the App. “Apex
            Enterprise Solutions” or “The Company” or “doodaa” or “We” or “Us”
            mean Apex Enterprise Solutions Limited or a legal employee or a
            representative of the Company. doodaa™ is a registered trademark of
            Apex Enterprise Solutions Limited, Copyright 2021-2022. All rights
            reserved.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Foreword
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            The doodaa App is published by or on behalf of Apex Enterprise
            Solutions Limited (“The Company”, “doodaa”, “We” or “Us”), a company
            registered in England and Wales (Company No. 08735516) of 71-75
            Shelton Street, LONDON, WC2H 9JQ.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            By downloading or otherwise accessing the doodaa App or Platform you
            agree to be bound by the following terms and conditions (“Terms”),
            our privacy policy and our cookie policy. If you have any queries
            about the App or these Terms, you can contact us at&nbsp;
            <a
              href="mailto:support@doodaa.co"
              className="text-theme underline leading-7 tracking-wider"
            >
              support@doodaa.co
            </a>
            . If you do not agree with these Terms, you should stop using the
            App immediately.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            You should print these terms or save them to your computer or
            handheld device for future reference. We amend these terms from time
            to time. Every time you wish to place an order, please check these
            Terms to ensure you understand the terms that will apply at the
            time.
          </p>
          <h2 className="text-2xl leading-6 text-text_color font-medium mt-6 font-circularstd">
            Your use of doodaa App
          </h2>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            1. You can only use doodaa when you have registered to do so. <br />
            1.1 You must be at least 18 years of age to use the App, the Website
            and the Platform. By using the App, the Website or the Platform and
            by agreeing to these terms of use, you warrant and represent that
            you are at least 18 years of age.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            2. You agree: <br />
            2.2.1 That all information and details provided by you to us (at the
            time of registration and during the use of the doodaa App or
            Platform) are or remain true, accurate and up-to-date in all
            respects and at all times. You can update or correct your details at
            any time through the doodaa App Account screen;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            2.2.2 Your use of doodaa grants you no rights in relation to our
            Intellectual Property (IP) rights (including, without limitation,
            transaction data, copyright, trademarks, logos, graphics,
            photographs, animations, videos, marketing material and text or
            rights in and to doodaa software, applications and website) or the
            Intellectual Property of our retail or advertising partners, other
            than the non-transferable, personal right to use and receive the
            doodaa services in accordance with these Terms;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            2.2.3 The App is made available for your own personal use only. You
            must not use any part of the materials on the App, the Website or
            the Platform for commercial purposes or for any illegal or
            unauthorised purpose. When you use the App, you must comply with all
            applicable UK laws and with any applicable international laws,
            including the local laws in your country of residence (“Applicable
            Laws”).
          </p>

          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            2.2.4 You will not modify, adapt, translate or reverse engineer any
            part of the App, the Website or the Platform or re-format or frame
            any portion of the pages comprising the App, the Website or the
            Platform save to the extent expressly permitted by these Terms or by
            law.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            2.2.5 If you print off, copy or download any part of the App, the
            Website or the Platform in breach of these terms of use, your right
            to use the App, the Website or the Platform will cease immediately
            and you must, at our option, return or destroy any copies of the
            materials you have made.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            2.2.6 You will not use doodaa: in any way that interrupts, damages,
            impairs or renders doodaa less efficient; to transfer files that
            contain viruses, or other harmful programs; to access or attempt to
            access the accounts of other users or to penetrate or attempt to
            penetrate any security measures; to disseminate any content which is
            defamatory, obscene, or may have the effect of being harassing,
            threatening or abusive to an individual or group of individuals on
            the basis of religion, gender, sexual orientation, race, ethnicity,
            age or disability or otherwise; to advertise or promote third party
            or your own products or services;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            2.2.7 To refrain from doing anything which we reasonably believe to
            be disreputable or capable of damaging our reputation;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            2.2.8 You acknowledge that we have limited control over the nature
            and content of information transmitted or received by you or other
            users of doodaa. Although we reserve the right to do so, we do not
            monitor such content in the usual course of business and will not be
            liable for any such content. If you have a complaint about another
            user please contact us through the App or via email.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            2.2.9 You acknowledge that you shall not attempt to abuse our
            platform by creating multiple, fraudulent or fictitious accounts.
            Any attempt to defraud us, by creating multiple accounts or by other
            means, shall be deemed a violation of our Terms & Conditions hence
            the legal contract between you and us. If we detect any such
            suspicious activity on your account then we reserve the right to
            take appropriate action against you, including cancelling or
            blocking your account and commencing legal proceedings, to recover
            any financial or reputational loss we may suffer as a result of such
            activity. Please also be aware that blatant abuse of electronic
            systems, by creating multiple accounts, for financial benefit or to
            defraud someone, is classed as a criminal offence under the UK
            Communications Act. All transaction data is held for up to six years
            to meet regulatory requirements. Moreover, if we need to investigate
            a suspicious activity then we reserve the right to report the
            transaction to legal or regulatory authorities and may share
            transactional data with credit reference agencies, banks and credit
            card issuers. This may affect your credit rating hence your ability
            to borrow money from regulated and reputed institutions.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            2.2.10 To indemnify us in full and on demand against any claims,
            loss, damage, costs or expense we suffer or incur directly or
            indirectly as a result of your use of the App otherwise than in
            accordance with these Terms or Applicable Laws.
          </p>

          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            3. Placing an Order
            <br /> 3.1. Placing an Order constitutes an offer by you to the
            Merchant to enter into a binding Contract with the Merchant for the
            purchase of Products or Goods comprised in the Order on the basis of
            these terms, which the Merchant is free to accept or reject at their
            absolute discretion.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            3.2. By ordering one or more alcoholic drinks, you are confirming to
            the Merchant that you are:
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            3.2.1. Over 18 years of age and can provide legal proof of this
            fact, if required;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            3.2.2. Not intoxicated;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            3.2.3. Not violating any Applicable Laws;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            3.2.4. Capable in legal terms of entering into binding contracts;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            3.2.5. Buying the drinks only for yourself and/or others over the
            age of 18;
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            3.2.6. If the above conditions, along with others, are not fulfilled
            the Merchant reserves the right not to fulfil the Order.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            3.3. On the Merchant receiving a push notification to confirm the
            preparation of the Order, the Contract is formed between you and the
            Merchant.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            3.4. We are not responsible for the fulfilment of Orders through the
            doodaa App. The Contract is between you and the Merchant. Any
            disputes in relation to the aforementioned Contract are to be
            resolved between you and the Merchant with no recourse to doodaa.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            4. About us <br />
            We operate the doodaa Platform, which consist of the doodaa App and
            the Website www.doodaa.co. Our business address is Apex Enterprise
            Solutions Limited, 71-75 Shelton Street, LONDON, WC2H 9JQ (UK).
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            5. Reliance
            <br /> Any materials, information or commentary provided in the App
            or the Website should at no time be relied upon and does not
            constitute advice. doodaa disclaims all liability as a result of any
            actions taken, or reliance placed, as a result of the aforementioned
            information or commentary.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            6. Our Liability <br /> 6.1. The material displayed on the App, the
            Website or the Platform is provided without any guarantees,
            conditions or warranties as to its accuracy. To the extent permitted
            by law, we and any third parties connected to us hereby expressly
            exclude:
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            6.1.1. All conditions, warranties and other terms which might
            otherwise be implied by statute, common law or the law of equity.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            6.2. Any liability for any direct, indirect or consequential loss or
            damage incurred by any user in connection with the App, the Website
            or the Platform or in connection with the use, inability to use, or
            results of the use of the App, the Website or the Platform, any
            websites linked to it and any materials posted on it.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            6.3. We are not liable to you in respect of the quality of the goods
            supplied to you by a Merchant, the quality of a Merchant’s services
            or the fulfilment or failure to fulfil any Order by a Merchant.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            6.4 Nothing in the Terms seeks to (i) affect your rights as a
            consumer or (ii) limit or exclude our liability for death or
            personal injury arising from our negligence, nor our liability for
            fraudulent misrepresentation or misrepresentation as to a
            fundamental matter, nor any other liability which cannot be excluded
            or limited under law.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            7. Concerns <br />
            If you have any issues or concerns with respect to any of the
            Merchants or Vendors listed on the App or your Order placed through
            the App, please contact us at&nbsp;
            <a
              href="mailto:support@doodaa.co"
              className="text-theme underline leading-7 tracking-wider"
            >
              support@doodaa.co
            </a>
            .
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            8. Accessing the App, the Website or the Platform <br />
            8.1 Access to the App, the Website or the Platform is permitted on a
            temporary basis and we reserve the right to withdraw or amend the
            service we provide on the App, the Website or the Platform without
            notice. We will not be liable if for any reason the App, the Website
            or the Platform is unavailable at any time or for any period.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            8.2. From time to time, we may restrict access to some parts of the
            App, the Website or the Platform, or the entirety of the App, the
            Website or the Platform, to users who have registered with us.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            8.3. If you choose, or you are provided with, a user identification
            code, password or any other piece of information as part of our
            security procedures, you must treat such information as
            confidential, and you must not disclose it to any third party. We
            have the right to disable any user identification code or password,
            whether chosen by you or allocated by us, at any time, if in our
            opinion you have failed to comply with any of the provisions of
            these terms of use.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            8.4. When using the App, the Website or the Platform, you must
            comply with the provisions of our acceptable use policy.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            8.5. You are responsible for making all arrangements necessary for
            you to have access to the App, the Website or the Platform either
            through a Private or Public internet connection. You are also
            responsible for ensuring that all persons who access the App, the
            Website or the Platform through your internet connection (whether
            Private or Public) are aware of these terms, and that they comply
            with them.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            8.6 You are responsible for accessing the App, the Website or the
            Platform through Private or Public internet connections. You must
            take all reasonable care to ensure these connections are secure and
            protect your identity and data. We will not be held liable for any
            loss of data, personal information and financial or reputational
            loss resulting from the use of any Private or Public internet
            connections that you decide to use to access the App, the Website or
            the Platform. This includes and is not limited to any internet
            connections provided by the Merchant or the Vendor.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            9. The App and the Website Change Regularly We aim to update the
            App, the Website or the Platform regularly, and may change the
            content at any time. If the need arises, we may suspend access to
            the App, the Website or the Platform, or close it indefinitely. Any
            of the material on the App, the Website or the Platform may be out
            of date at any given time, and we are under no obligation to update
            such material.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            10. Privacy Policy All personal information/data is processed in
            accordance with our privacy policy.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            11. Termination <br />
            11.1 We may terminate these Terms and close any account you have
            with us by giving you 7 days’ notice in writing by email to your
            registered email address. We may also terminate these Terms and
            close your account without notice if you breach any of your
            obligations under these Terms, if bankruptcy proceedings are brought
            against you or if you do not pay a court judgment on time.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            11.2 We reserve the right to suspend, restrict or terminate your
            access to the App at any time without notice if we have reasonable
            grounds to believe you have breached any of these Terms. This shall
            not limit our right to take any other action against you that we
            consider appropriate to defend our rights or those of any other
            person.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            11.3 You are under no obligation to use the service and may choose
            to stop using it at any time.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            12. Please Drink Responsibly and know your allergens
            <br /> 12.1 You agree that you will only use the App, the Website or
            the Platform to drink responsibly and that you are solely
            responsible for monitoring your drinking.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            12.2 If you feel that you have a problem with alcohol and want to
            seek help, please talk to your doctor or visit the Drink Aware
            Trust’s website.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            12.3 We accept no responsibility for any adverse reaction or injury
            suffered by you when consuming Products or Goods sold to You by a
            Merchant. You should ensure that any Products or Goods purchased by
            you do not contain any substances to which you are allergic before
            you consume them.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            13. Jurisdictions and Applicable Law <br />
            13.1 The English courts will have non-exclusive jurisdiction over
            any claim arising from, or related to, these Terms, any Order, and
            the use of the App, the Website or the Platform. However, if you are
            a resident of Northern Ireland you may also bring proceedings in
            Northern Ireland, and if you are a resident of Scotland, you may
            also bring proceedings in Scotland.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            13.2 These Terms and any dispute or claim arising out of or in
            connection with them or their subject matter or formation (including
            non-contractual disputes or claims) shall be governed by and
            construed in accordance with the laws of England.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            14. Variations <br />
            14.1. We may revise these Terms from time to time. The revised Terms
            will apply to the use of the App and the Website from the date of
            publication of the revised Terms on the website.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            14.2. It is your responsibility to review this page and the Terms
            for any changes we make from time to time.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            14.3. Any provisions contained in these Terms may also be superseded
            by provisions or notices published elsewhere on the App, the Website
            or the Platform and you shall be deemed to have notice of any such
            additional or superseded terms.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            15. General <br />
            15.1 If any court or competent authority decides that any of the
            provisions of these Terms are invalid, unlawful or unenforceable to
            any extent, the term will, to that extent only, be severed from the
            remaining terms, which will continue to be valid to the fullest
            extent permitted by law.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            15.2 No failure or delay by a party to exercise any right or remedy
            provided under these Terms or by law shall constitute a waiver of
            that or any other right or remedy, nor shall it preclude or restrict
            the further exercise of that or any other right or remedy. No single
            or partial exercise of such right or remedy shall preclude or
            restrict the further exercise of that or any other right or remedy.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            15.3 A person who is not party to these Terms shall not have any
            rights under or in connection with them under the Contracts (Rights
            of Third Parties) Act 1999.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            16. Your Concerns If you have any concerns about material which
            appears on the App then please contact&nbsp;
            <a
              href="mailto:support@doodaa.co"
              className="text-theme underline leading-7 tracking-wider"
            >
              support@doodaa.co
            </a>
            .
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            doodaa™ is a registered trademark of Apex Enterprise Solutions
            Limited, Copyright 2021-2022.
          </p>
          <p className="my-2 text-text_color leading-8 text-base font-circularstd font-normal ">
            All rights reserved. Last updated: 07 February 2022
          </p>
        </div>
      </div>
    </>
  );
}
